import React, {useState} from 'react';
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook} from 'react-icons/fa';
import Logo from '../assets/logo.svg';
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {Link} from 'react-scroll';

const Navbar = () => {

    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    return(
        <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-200'>
        <div>
            <img src={Logo} alt='Logo' style={{width: '140px'}}/>
        </div>

            {/* Menu */}

                <ul className='md:flex hidden'>
                    <li className='hover:text-lime-400 duration-200'>
                        {''}
                        <Link to="home" smooth={true} duration={500}>
                            Home
                            </Link>
                    </li>
                    <li className='hover:text-lime-400 duration-200'>
                        {''}
                        <Link to="about" smooth={true} duration={500}>
                            About
                        </Link>
                    </li>
                    <li className='hover:text-lime-400 duration-200'>
                        <Link to="skills" smooth={true} duration={500}>
                            Skills
                        </Link>
                    </li>
                    <li className='hover:text-lime-400 duration-200'>
                        <Link to="work" smooth={true} duration={500}>
                            Work
                        </Link>
                    </li>
                    <li className='hover:text-lime-400 duration-200'>
                        <Link to="contact" smooth={true} duration={500}>
                            Contact
                        </Link>
                    </li>
                </ul>



            {/* Hamburger */}
            <div onClick={handleClick} className='md:hidden z-10'>
                {!nav ? <FaBars/> : <FaTimes/>}
            </div>

            {/* Mobile Menu */}
                <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center duration-500'}>
                    <li className='py-6 text-4xl'>
                        <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                            Home
                        </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                        <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                            About
                        </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                        <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                            Skills
                        </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                        <Link onClick={handleClick} to="work" smooth={true} duration={500}>
                            Work
                        </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                        <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                            Contact
                        </Link>
                    </li>
                </ul>

            {/* Social icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                        <a href='https://www.linkedin.com/in/steven-dautrich-0aa53b236/' className='flex justify-between items-center w-full text-gray-300'>
                            LinkedIn <FaLinkedin size={30}/>
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-800'>
                        <a href='https://github.com/imp87' className='flex justify-between items-center w-full text-gray-300'>
                            Github <FaGithub size={30}/>
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-green-600'>
                        <a href='mailto:steven%40dautrich.net' className='flex justify-between items-center w-full text-gray-300'>
                            Email <HiOutlineMail size={30}/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar