import React from 'react';
import {HiArrowNarrowRight} from 'react-icons/hi';
import {Link} from 'react-scroll';

const Home = () => {

    return(
        <div name='home' className='w-full h-screen bg-[#0a192f]'>

            {/* Home */}

            <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
                <p className='text-lime-400'>Hi, my name is</p>
                <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Steven Dautrich</h1>
                <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I'm a Frontend Developer.</h2>
                <p className='text-[#8892b0] py-4 max-w-[700px]'>As a frontend developer with a focus on Node.js and React, I have a passion
                    for developing interactive and user-friendly web applications, characterized by
                    seamless integration of technologies and a responsive user interface.</p>
                <div>
                    <Link to='work' smooth={true} duration={500}>
                    <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-lime-400 hover:border-lime-400 duration-100'>View Work

                    <span className='group-hover:rotate-90 duration-300'>
                        <HiArrowNarrowRight className='ml-3'/>
                    </span>
                    </button>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default Home