import React from 'react';
import WorkImg1 from '../assets/projects/smokestopper.jpg'
import WorkImg2 from '../assets/projects/workImg.jpeg'
import WorkImg3 from '../assets/projects/q-linx.png'
import '../index.css'

const Work = () => {
    return (
        <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-lime-400'>Work</p>
                    <p className='py-6'>// Check out my Work</p>
                </div>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                    <div
                        className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center text-center items-center mx-auto content-div'
                        style={{backgroundImage: `url(${WorkImg1})`}}>
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
            <span className='text-2xl font-bold text-white tracking-wider'>
                Smokestopper
            </span>
                            <div className='pt-8 text-center'>
                                <a href='https://smoke-stopper.de'>
                                    <button
                                        className='text-center rounded-lg px-3 py-3 m-2 bg-white text-gray-700 font-bold text-lg hover:text-white hover:bg-black duration-100'>Demo
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center text-center items-center mx-auto content-div'
                        style={{backgroundImage: `url(${WorkImg2})`}}>
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
            <span className='text-2xl font-bold text-white tracking-wider'>
                Kento RankmeInterface
            </span>
                            <div className='pt-8 text-center'>
                                <a href='https://github.com/imp87/kento-rankme-webinterface'>
                                    <button
                                        className='text-center rounded-lg px-3 py-3 m-2 bg-white text-gray-700 font-bold text-lg hover:text-white hover:bg-black duration-100'>Code
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center text-center items-center mx-auto content-div'
                        style={{backgroundImage: `url(${WorkImg3})`}}>
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
            <span className='text-2xl font-bold text-white tracking-wider'>
                Q-linx Game-Hoster
            </span>
                            <div className='pt-8 text-center'>
                                <a href='https://q-linx.de/'>
                                    <button
                                        className='text-center rounded-lg px-3 py-3 m-2 bg-white text-gray-700 font-bold text-lg hover:text-white hover:bg-black duration-100'>Demo
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Work