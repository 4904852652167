import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import FireBase from '../assets/firebase.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Mysql from '../assets/mysql.png';
import Azure from '../assets/logo_azure.webp'

const Skills = () => {

    return(
        <div name='skills' className='bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full '>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-lime-400 text-gray-300'>Experience</p>
                    <p className='py-4'>// These are the technologies I've worked with</p>
                </div>


                <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={HTML} alt='HTML' className='w-20 mx-auto'/>
                        <p>HTML</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={CSS} alt='HTML' className='w-20 mx-auto'/>
                        <p>CSS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={JavaScript} alt='HTML' className='w-20 mx-auto'/>
                        <p>Javascript</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={Node} alt='HTML' className='w-20 mx-auto'/>
                        <p>NodeJS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={ReactImg} alt='HTML' className='w-20 mx-auto'/>
                        <p>React</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={GitHub} alt='HTML' className='w-20 mx-auto'/>
                        <p>Git</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={Mysql} alt='HTML' className='w-20 mx-auto'/>
                        <p>MySQL</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <img src={Azure} alt='HTML' className='w-20 mx-auto'/>
                        <p>Azure</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;