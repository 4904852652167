import React, {useEffect} from 'react'
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from "./components/About";
import Skills from "./components/Skills"
import Work from "./components/Work";
import Contact from "./components/Contact";

function App() {

  const websiteId = '098edc4b-abef-440e-9aa9-d426ffa31a70'

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://analytics.cordes-cloud.net/script.js';
    script.setAttribute('data-website-id', websiteId);
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Aufräumen, wenn die Komponente entladen wird
      document.body.removeChild(script);
    };
  }, [websiteId]);

  return (
    <div>
    <Navbar/>
      <Home/>
      <About/>
      <Skills/>
      <Work/>
      <Contact/>
    </div>
  );
}

export default App;
