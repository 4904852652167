import React from 'react';

const Contact = () => {
    return(
        <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4 '>
            <form method='POST' action='https://getform.io/f/7a589906-62f6-461c-aec3-49137cb1463e' className='flex flex-col max-w-[600px] w-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-lime-400 text-gray-300'>Contact</p>
                    <p className='text-gray-300 py-4'>// Submit the form below or shoot me an email - steven@dautrich.net</p>
                </div>
                <input className='bg-[#ccd6f6] p-2' type='text' placeholder='Name' name='name' />
                <input className='my-4 p-2 bg-[#ccd6f6]' type='email' placeholder='E-Mail Adress' name='email'/>
                <textarea className='bg-[#ccd6f6] p-2' name='message' rows='10' placeholder='Please type in your message..'></textarea>
                <button className='my-5 text-white border-2 hover:bg-lime-400 hover:border-lime-400 px-4 py-3 mx-auto flex items-center duration-100'>Lets work together!</button>
            </form>
        </div>
    )
}

export default Contact;